import React from "react";
import { Link as RebassLink } from "rebass";
import { Link as RouterLink } from "react-router-dom";

const Link = (props) => (
	<RebassLink exact as={RouterLink} {...props}>
		{props.children}
	</RebassLink>
);

export default Link;
