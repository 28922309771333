import React, { useState } from "react";
import cx from "classnames";
import { Box, Image, Card, Heading, Text, Flex, Button } from "rebass";
import Slider from "react-slick";
import Link from "components/Link";
import { sliderSettings } from "./settings";
import "./index.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoSlider = (props) => {
	const showDetail = props.name === props.activeSlider;
	const [activeSlide, setActiveSlide] = useState(-1);

	return (
		<Box
			{...props}
			sx={{
				...props.sx,
				position: "relative",
				"&.show-detail": {
					paddingBottom: "500px",
				},
			}}
			className={cx("video-slider-container", { "show-detail": showDetail })}
		>
			<Box px={[10, 10, 30, 100]} pt={5} pb={0}>
				<Heading variant="h2" px={2}>
					{props.title}
				</Heading>
				<Slider {...sliderSettings} className="video-slider">
					{props.slides.map((slide, index) => {
						const active = showDetail && activeSlide === index;
						return (
							<Box
								key={`video-slide-${index}`}
								p={[1, 1, 2, 3]}
								pb={[3, 3, 3]}
								sx={{ cursor: "pointer" }}
							>
								<Box
									onClick={() => {
										setActiveSlide(index);
									}}
									className={cx("slide-inner", {
										active: active,
									})}
									sx={{
										position: "relative",
										transition: "short",
										"&.active:before": {
											borderBottomColor: "primary",
										},
										"&.active:after": {
											borderTopColor: "primary",
										},
									}}
									link
								>
									<Box
										textAlign="center"
										mt={2}
										sx={{
											display: ["block", "none"],
											position: "absolute",
											bottom: 15,
											right: 15,
										}}
										onClick={() => {
											props.click(props.name);
											setActiveSlide(index);
										}}
									>
										<Button variant="circle">
											<i className="icon-caret-down"></i>
										</Button>
									</Box>

									{showDetail ? (
										<Image src={slide.image} />
									) : (
										<Link to={slide.link}>
											<Image src={slide.image} />
										</Link>
									)}
									{slide.progress && (
										<Box
											className="video-progress"
											sx={{
												"&:after": {
													width: `${slide.progress}%`,
													bg: "primary",
												},
											}}
										/>
									)}
									<Card
										onClick={() => {
											props.click(props.name);
											setActiveSlide(index);
										}}
										className="slide-card"
										sx={{
											opacity: 0,
											transition: "short",
											position: "absolute",
											top: "100%",
											left: 0,
											width: "100%",
											display: "none",
										}}
									>
										<Text>{slide.author}</Text>
										<Heading variant="h3" mb={2}>
											{slide.title}
										</Heading>
										<Box mb={2}>
											<Text>{slide.duration}</Text>
										</Box>
										<Text>{slide.desc}</Text>
										<Box textAlign="center" mt={2}>
											<Text
												as="i"
												className="icon-caret-down"
												sx={{ cursor: "pointer" }}
												color="primary"
											></Text>
										</Box>
									</Card>
								</Box>
							</Box>
						);
					})}
				</Slider>
			</Box>
			{showDetail && (
				<Box
					sx={{
						position: "absolute",
						left: 0,
						width: "100%",
						height: "500px",
					}}
				>
					<Box
						className="video-slider-detail"
						sx={{
							backgroundImage: `url(${require("assets/img/video-placeholder.jpg")})`,
						}}
					>
						<Box variant="inner" py={5}>
							<Box
								variant="link"
								sx={{
									position: "absolute",
									right: 3,
									top: 3,
									fontSize: 4,
									lineHeight: 1,
								}}
							>
								<i className="icon-times" onClick={() => props.click(null)}></i>
							</Box>
							<Flex justifyContent="space-between" flexWrap="wrap">
								<Box width={[1, 6 / 12, 8 / 12]}>
									<Heading variant="h2" mb={2}>
										Arise and Unwind
									</Heading>
									<Text mb={3}>Jafar Alexander</Text>
									<Text mb={3} fontSize={3}>
										<Text as="span" mr={2}>
											Vinyasa
										</Text>
										<Text as="span" mr={2}>
											Level
										</Text>
										<Text as="span" mr={2}>
											115 mins
										</Text>
									</Text>
									<Text mb={3}>
										Wake up to this well-rounded vinyasa practice to uncurl and
										create space for the day. Travel through simple movements to
										warm up your entire body so you can step into the day with a
										sense of freedom and clarity.
									</Text>
									<p>
										<Text as="span" mr={2}>
											<i className="icon-thumbs-up"></i> 98%
										</Text>
										<Text as="span" mr={2}>
											<i className="icon-thumbs-down"></i> 2%
										</Text>
										<Text as="span" mr={2}>
											<i className="icon-comment-alt"></i> 26Comments
										</Text>
									</p>
									<Flex alignItems="center">
										<Button variant="circle">
											<i className="icon-play-solid"></i>
										</Button>
										<Link display="inline-block" ml={2} to="/video">
											<Heading variant="h4" mb={0}>
												Watch Now
											</Heading>
										</Link>
									</Flex>
								</Box>
								<Box
									width={[null, 5 / 12, 3 / 12]}
									sx={{ display: ["none", "block"] }}
								>
									<Flex alignItems="center" mb={2} as={Link}>
										<Button variant="circleOutline" mr={2}>
											<i className="icon-plus"></i>
										</Button>
										<Heading variant="h5">Add to Playlist</Heading>
									</Flex>
									<Flex alignItems="center" mb={2} as={Link}>
										<Button variant="circleOutline" mr={2}>
											<i className="icon-share"></i>
										</Button>
										<Heading variant="h5">Share</Heading>
									</Flex>
									<Flex alignItems="center" mb={2} as={Link}>
										<Button variant="circleOutline" mr={2}>
											<i className="icon-film"></i>
										</Button>
										<Heading variant="h5">Preview</Heading>
									</Flex>
									<Flex alignItems="center" mb={2} as={Link}>
										<Button variant="circleOutline" mr={2}>
											<i className="icon-list-ul"></i>
										</Button>
										<Heading variant="h5">Full Details</Heading>
									</Flex>
								</Box>
							</Flex>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default VideoSlider;
