import React from "react";
import { Box } from "rebass";
import Header from "components/Header";
import Footer from "components/Footer";

const PageContainer = ({ header, footer, children }) => {
	return (
		<>
			{header && <Header />}
			<Box paddingTop={header ? 60 : 0}>{children}</Box>
			{footer && <Footer />}
		</>
	);
};

export default PageContainer;
