import React, { useEffect } from "react";
import { Box, Link } from "rebass";

const Menu = (props) => {
	const menu = React.createRef();

	useEffect(() => {
		document.addEventListener(
			"mousedown",
			(e) => {
				if (menu.current && !menu.current.contains(e.target)) {
					props.blur(false);
				}
			},
			false
		);
	}, [menu, props]);

	return (
		<Box
			{...props}
			ref={menu}
			sx={{
				position: "absolute",
				right: -1,
				top: "calc(100% + 24px)",
				width: 300,
				boxShadow: "normal",
				bg: "background_lighter",
				py: 2,
				"&:before": {
					content: "''",
					position: "absolute",
					right: 1,
					top: 0,
					transform: "translateY(-100%)",
					border: "12px solid transparent",
					borderBottomColor: "background_lighter",
				},
			}}
		>
			<Box as="ul" sx={{ listStyle: "none", px: 0 }}>
				<Box as="li">
					<Link href="/myaccount" variant="nav">
						My Account
					</Link>
				</Box>
				<Box as="li">
					<Link href="/logout" variant="nav">
						Logout
					</Link>
				</Box>
			</Box>
		</Box>
	);
};

export default Menu;
