import React, { useContext } from "react";
import { Box, Flex, Button } from "rebass";
import Logo from "components/Header/Logo";
import Link from "components/Link";
import DarkThemeContext from "helper/context";

const Footer = () => {
	const { darkTheme } = useContext(DarkThemeContext);

	return (
		<>
			<Flex
				variant="inner"
				className="footer"
				justifyContent="center"
				fontSize={1}
				mt={5}
				sx={{
					borderTop: `1px solid rgba(${
						darkTheme ? "255,255,255" : "0,0,0"
					},.1)`,
				}}
			>
				<Box width={[1, 3 / 4]} pt={[20, 50]} pb={20}>
					<Logo darkTheme={darkTheme} mb={2} />
					<Flex flexWrap="wrap">
						<Box width={[1, 1 / 2]} mb={[3, 0]}>
							<Box py={1}>
								<Link to="/">About Us</Link>
							</Box>
							<Box py={1}>
								<Link to="/">Privacy Policy</Link>
							</Box>
							<Box py={1}>
								<Link to="/">Terms of Use</Link>
							</Box>
						</Box>
						<Box width={[1, 1 / 2]} textAlign={["center", "right"]}>
							<Button variant="circle" m={1}>
								<i className="icon-facebook-f"></i>
							</Button>
							<Button variant="circle" m={1}>
								<i className="icon-twitter"></i>
							</Button>
							<Button variant="circle" m={1}>
								<i className="icon-instagram"></i>
							</Button>
							<Button variant="circle" m={1}>
								<i className="icon-youtube"></i>
							</Button>
							<Button variant="circle" m={1}>
								<i className="icon-pinterest-p"></i>
							</Button>
						</Box>
					</Flex>
				</Box>
			</Flex>
			<Box
				variant="inner"
				bg="background_lighter"
				textAlign="center"
				fontSize={0}
				py={3}
			>
				&copy; 2020 Whole TV. All Rights Reserved.
			</Box>
		</>
	);
};

export default Footer;
