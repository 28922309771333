import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Flex, Box, Text, Button, Heading, Image } from "rebass";

import Banner from "components/Banner";
import Link from "components/Link";

const Detail = (props) => {
	const { pathname } = useLocation();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<Banner
				desc="Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core engagement with twists and standing postures, and finally focus on the upper back and neck for a complete head to toe flow."
				backgroundImage={require("assets/img/bg-placeholder-1920.png")}
			>
				<Flex color="white" flexWrap="wrap">
					<Box width={[1, 3 / 4]} marginBottom={3}>
						<Heading variant="h1" sx={{ textShadow: "0 0 5px black" }}>
							Head to Toe Flow
						</Heading>
						<Box my={3}>
							<Text as="span" mr={2}>
								S14:Ep61
							</Text>
							<Text as="span" mr={2}>
								1hr, 1 min
							</Text>
							<Text as="span" mr={2}>
								July 25, 2019
							</Text>
							<Text as="span">Guest: Dr. Edward Group, DC</Text>
						</Box>
						<Box>
							<Text as="span" mr={2}>
								<i className="icon-thumbs-up"></i> 98%
							</Text>
							<Text as="span" mr={2}>
								<i className="icon-thumbs-down"></i> 2%
							</Text>
						</Box>
					</Box>
					<Box width={[1, 1 / 4]}>
						<Link to="/video">
							<Button mb={3}>Watch Now</Button>
						</Link>
						<Flex alignItems="center" mb={1} as={Link} to="/category">
							<Text as="span" mr={2}>
								<i className="icon-bell"></i>
							</Text>
							<Heading variant="h6">Follow Series</Heading>
						</Flex>
						<Flex alignItems="center" mb={1} as={Link} to="/category">
							<Text as="span" mr={2}>
								<i className="icon-plus"></i>
							</Text>
							<Heading variant="h6">Add to Playlist</Heading>
						</Flex>
						<Flex alignItems="center" mb={1} as={Link} to="/category">
							<Text as="span" mr={2}>
								<i className="icon-share"></i>
							</Text>
							<Heading variant="h6">Share</Heading>
						</Flex>
						<Flex alignItems="center" mb={1} as={Link} to="/category">
							<Text as="span" mr={2}>
								<i className="icon-film"></i>
							</Text>
							<Heading variant="h6">Preview</Heading>
						</Flex>
						<Flex alignItems="center" mb={1} as={Link} to="/category">
							<Text as="span" mr={2}>
								<i className="icon-list-alt"></i>
							</Text>
							<Heading variant="h6">Full Series</Heading>
						</Flex>
					</Box>
				</Flex>
			</Banner>
			<Box variant="inner">
				<Flex py={6} flexWrap="wrap">
					<Box width={[1, 2 / 3]} pr={[0, 6]} mb={[3, 0]}>
						<p>
							Awaken and tune up all of the major muscle groups in this steady
							flow. Build your practice from the ground up beginning with feet,
							ankles, and legs, then find core engagement with twists and
							standing postures, and finally focus on the upper back and neck
							for a complete head to toe flow.
						</p>
						<p>Props: 2 blocks (optional)</p>
					</Box>
					<Box width={[1, 1 / 3]}>
						<Box variant="border" textAlign="center" p={4}>
							<h1>36</h1>
							<p>
								<i className="icon-comment-alt"></i>
							</p>
							<Link to="/comments">View All Comments</Link>
						</Box>
					</Box>
				</Flex>
			</Box>
			<Box variant="inner" mt={3}>
				<h2>RELATED</h2>
				<Flex flexWrap="wrap">
					{Array.from({ length: 12 }).map((_, index) => (
						<Box
							key={`related-video-${index}`}
							width={[1, "23.5%"]}
							mb={5}
							mr={index % 4 === 3 ? 0 : "2%"}
						>
							<Flex sx={{ position: "relative" }} mb={2}>
								<Image
									src={require("assets/img/video-placeholder.jpg")}
									alt=""
									sx={{ cursor: "pointer" }}
									onClick={() => history.push("/detail")}
								/>
								<Box sx={{ position: "absolute", bottom: 80, right: 3 }}>
									<Link to="/video">
										<Button variant="circle">
											<i className="icon-play-solid"></i>
										</Button>
									</Link>
								</Box>
								<Box
									to="/detail"
									sx={{ position: "absolute", bottom: 3, right: 3 }}
								>
									<Button variant="circleOutline">
										<i className="icon-ellipsis-h-alt"></i>
									</Button>
								</Box>
							</Flex>
							<Flex justifyContent="space-between">
								<Text>Open Minds</Text>
								<Text>
									<Text as="span" mr={2}>
										<i className="icon-thumbs-up"></i> 95%
									</Text>
									<Text as="span" mr={2}>
										<i className="icon-thumbs-down"></i> 5%
									</Text>
								</Text>
							</Flex>
							<Text>
								<strong>Out of Body Experiences with Luis Minero</strong>
							</Text>
							<Text>
								<Text as="span" mr={2}>
									S14:Ep7
								</Text>
								<Text as="span">59 mins</Text>
							</Text>
						</Box>
					))}
				</Flex>
			</Box>
		</>
	);
};

export default Detail;
