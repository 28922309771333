import React from "react";
import { Box, Flex } from "rebass";
import { Input } from "@rebass/forms";

const Search = (props) => (
	<Box {...props} sx={{ ...props.sx, position: "relative" }}>
		<Input
			placeholder="Search..."
			height={40}
			bg={["white", "white", "transparent"]}
		/>
		<Flex
			sx={{
				alignItems: "center",
				position: "absolute",
				right: 1,
				top: 0,
				height: "100%",
			}}
		>
			<i className="icon-search"></i>
		</Flex>
	</Box>
);

export default Search;
