const colors = {
	text: "#4a4a4a",
	background: "#fff",
	background_light: "#fff",
	background_lighter: "#fff",
	bg_header: "#fff",
	primary: "rgba(0, 114, 210)",
	primaryDark: "rgba(0, 113, 114)",
};

export const theme = {
	breakpoints: ["768px", "1024px", "1440px"],
	fontSizes: [12, 14, 16, 20, 22, 25, 30, 35, 40],
	colors,
	transition: {
		short: ".3s ease-in-out",
	},
	space: [0, 10, 15, 20, 30, 40, 50],
	sizes: {
		avatar: 25,
	},
	radii: {
		default: 4,
		circle: 99999,
	},
	fonts: {
		body: "poppins, sans-serif",
		heading: "inherit",
		icon: "whole-tv",
	},
	fontWeights: {
		body: 300,
		heading: 700,
		bold: 700,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.25,
	},
	shadows: {
		normal: "0 0 20px rgba(0,0,0,.2)",
		card: "0 20px 20px 0 rgba(0,0,0,.3)",
	},
	text: {
		heading: {
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			marginTop: 0,
			marginBottom: ".5em",
		},
		h1: {
			variant: "text.heading",
			fontSize: [6, 7, 8],
		},
		h2: {
			variant: "text.heading",
			fontSize: [5, 6, 7],
		},
		h3: {
			variant: "text.heading",
			fontSize: [4, 5, 6],
		},
		h4: {
			variant: "text.heading",
			fontSize: [3, 4, 5],
		},
		h5: {
			variant: "text.heading",
			fontSize: [3, 3, 4],
		},
		h6: {
			variant: "text.heading",
			fontSize: [2, 2, 3],
		},
		badge: {
			color: "background",
			display: "inline-block",
			py: "5px",
			px: 2,
			backgroundImage: `linear-gradient(to right, ${colors.primary}, ${colors.primaryDark})`,
			fontSize: 0,
		},
	},
	variants: {
		avatar: {
			width: "avatar",
			height: "avatar",
			borderRadius: "circle",
		},
		border: {
			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: "text",
		},
		link: {
			color: "inherit",
			textDecoration: "none",
			transition: "short",
			cursor: "pointer",
			":hover,:focus,&.active": {
				color: "primary",
			},
		},
		nav: {
			variant: "variants.link",
			display: "block",
			px: 4,
			py: 1,
			":hover,:focus,&.active": {
				color: "primary",
				bg: "background_light",
			},
		},
		card: {
			p: 2,
			backgroundImage: `linear-gradient(to right, ${colors.background_light}, ${colors.background_lighter})`,
			boxShadow: "card",
		},
		slideArrow: {
			width: 40,
			height: 40,
			borderRadius: "circle",
			boxShadow: "normal",
			zIndex: 3,
			"&:hover:before": {
				color: "primary",
				fontWeight: "bold",
			},
			"&:before": {
				color: "text",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "100%",
			},
		},
		slideArrowWithBg: {
			variant: "variants.slideArrow",
			bg: colors.background_light,
			"&:hover": {
				bg: colors.background_lighter,
			},
		},
		inner: {
			px: [4, 4, 6],
			py: 1,
		},
		content: {
			py: [4, 4, 6],
		},
	},
	buttons: {
		primary: {
			fontSize: 2,
			fontWeight: "bold",
			color: "white",
			bg: "primary",
			borderRadius: "default",
			cursor: "pointer",
			transition: "short",
			display: "inline-flex",
			alignItems: "center",
			justifyContent: "center",
		},
		outline: {
			variant: "buttons.primary",
			color: "white",
			bg: "transparent",
			boxShadow: "inset 0 0 2px",
		},
		circle: {
			variant: "buttons.primary",
			borderRadius: "circle",
			width: ["35px", "40px", "45px"],
			height: ["35px", "40px", "45px"],
			padding: 0,
		},
		circleOutline: {
			variant: "buttons.circle",
			bg: "transparent",
			color: "white",
			borderStyle: "solid",
			borderColor: "white",
			borderWidth: "2px",
			borderRadius: "circle",
		},
		secondary: {
			variant: "buttons.primary",
			color: "white",
			bg: "secondary",
		},
	},
	styles: {
		root: {
			fontFamily: "body",
			lineHeight: "body",
			fontWeight: "body",
			fontSize: [1, 1, 2],
		},
	},
};

const colors_dark = {
	...colors,
	text: "#8e98b3",
	background: "#041433",
	background_light: "#07132f",
	background_lighter: "#030c24",
	bg_header: "rgba(0,0,0,.9)",
};

export const theme_dark = {
	...theme,
	colors: colors_dark,
	variants: {
		...theme.variants,
		card: {
			p: 2,
			backgroundImage: `linear-gradient(to bottom, ${colors_dark.background_light}, ${colors_dark.background_lighter})`,
			boxShadow: "card",
		},
		slideArrowWithBg: {
			variant: "variants.slideArrow",
			bg: colors_dark.background_light,
			"&:hover": {
				bg: colors_dark.background_lighter,
			},
		},
	},
};
