import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text } from "rebass";
import { MediaPlayer } from "dashjs";
import { useLocation, useHistory } from "react-router-dom";

import "./index.css";

const Video = () => {
	const [video, setVideo] = useState(null);
	const [showDesc, setShowDesc] = useState(false);
	const { pathname } = useLocation();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		const video = {
			title: "Head to Toe Flow",
			desc:
				"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
			published_at: "November 2015",
			image: require("assets/img/video-placeholder.jpg"),
			url: "https://d1vn3uqlt371jx.cloudfront.net/output.mpd",
		};
		setVideo(video);

		const player = MediaPlayer().create();
		player.initialize(document.querySelector("#video-player"), video.url, true);
	}, []);

	return (
		<Box
			sx={{
				position: "absolute",
				height: "100%",
				width: "100%",
				background: "#000",
				color: "white",
			}}
			className="video-player-wrapper"
		>
			<Box
				sx={{
					width: "178vh",
					margin: "0 auto",
					position: "relative",
					height: "100vh",
					maxWidth: "100%",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						width: "100%",
						height: 0,
						paddingTop: "56.25%",
						top: "50%",
						transform: "translateY(-50%)",
						overflow: "hidden",
					}}
				>
					<Box
						sx={{
							position: "absolute",
							left: "5%",
							top: 30,
							zIndex: 1,
							width: ["90%", "50%"],
						}}
					>
						<Flex alignItems="start" mb={2}>
							<Box flexBasis={60}>
								<Button
									variant="circle"
									onClick={() => history.goBack()}
									sx={{ cursor: "pointer" }}
								>
									<i className="icon-chevron-left"></i>
								</Button>
							</Box>
							<Box flex={1}>
								<Text variant="h4" mb="1">
									{video?.title}
								</Text>
								<Text>{video?.published_at}</Text>
								<Box py={2} sx={{ cursor: "pointer" }}>
									<i
										className={
											showDesc ? "icon-ellipsis-h" : "icon-ellipsis-h-alt"
										}
										onClick={() => {
											setShowDesc(!showDesc);
										}}
									></i>
								</Box>
								{showDesc && (
									<Box bg="rgba(0,0,0,.5)" padding={30}>
										{video?.desc}
									</Box>
								)}
							</Box>
						</Flex>
					</Box>
					<Box
						as="video"
						id="video-player"
						controls
						sx={{
							position: "absolute",
							left: 0,
							top: 0,
							width: "100%",
							height: "100%",
						}}
					></Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Video;
