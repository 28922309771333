import React from 'react';
import { Box } from "rebass";

const PrevArrow = ({ className, onClick }) => (
	<Box
		className={`${className} icon-chevron-left`}
		onClick={onClick}
		variant="slideArrowWithBg"
	/>
);

const NextArrow = ({ className, onClick }) => (
	<Box
		className={`${className} icon-chevron-right`}
		onClick={onClick}
		variant="slideArrowWithBg"
	/>
);
export const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};