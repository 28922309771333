import React, { useState, useContext } from "react";
import Search from "components/Header/Search";
import Avatar from "components/Header/Avatar";
import Logo from "components/Header/Logo";
import Nav from "components/Header/Nav";
import Menu from "components/Header/Menu";
import { Flex, Text } from "rebass";

import DarkThemeContext from "helper/context";

import "./index.css";

const Header = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [expandNav, setExpandNav] = useState(false);
	const { darkTheme, setDarkTheme } = useContext(DarkThemeContext);

	const addBodyClass = () => document.body.classList.add("nav-expanded");
	const removeBodyClass = () => document.body.classList.remove("nav-expanded");

	return (
		<Flex
			flexWrap="wrap"
			variant="inner"
			sx={{
				position: ["absolute", "fixed"],
				width: "100%",
				zIndex: 9999,
				alignItems: "center",
				bg: "bg_header",
				height: 60,
				top: 0,
				justifyContent: "space-between",
				boxShadow: "0 5px 15px rgba(0,0,0,.2)",
			}}
		>
			<Logo darkTheme={darkTheme} />
			<Nav
				id="nav-menu"
				width={[1, "auto"]}
				mr="auto"
				sx={{
					order: [2, "unset"],
					display: [expandNav ? "block" : "none", "block"],
				}}
			/>
			<Flex justifyContent="flex-end" alignItems="center">
				<Search mr={2} sx={{ display: ["none", "none", "block"] }} />
				<Flex
					fontSize={25}
					mr={3}
					sx={{
						cursor: "pointer",
						"&:hover": {
							textShadow: `0 0 15px ${darkTheme ? "cyan" : "orange"}`,
						},
					}}
					onClick={() => setDarkTheme(!darkTheme)}
					title="Toggle Theme"
				>
					<i className={darkTheme ? "icon-moon-stars" : "icon-sun"}></i>
				</Flex>
				<Flex fontSize={25} mr={3} sx={{ cursor: "pointer" }}>
					<i className="icon-bell"></i>
				</Flex>
				<Flex sx={{ position: "relative" }}>
					<Avatar
						mr={[3, 0]}
						src={require(`assets/img/avatar${darkTheme ? "_light" : ""}.png`)}
						alt="avatar"
						onClick={() => setShowMenu(!showMenu)}
					/>
					{showMenu && <Menu blur={setShowMenu} />}
				</Flex>
				<Text
					width={22}
					fontSize={25}
					textAlign="center"
					sx={{ cursor: "pointer", display: ["block", "none"] }}
					onClick={() => {
						expandNav ? removeBodyClass() : addBodyClass();
						setExpandNav(!expandNav);
					}}
				>
					{expandNav ? (
						<i className="icon-times"></i>
					) : (
						<i className="icon-bars"></i>
					)}
				</Text>
			</Flex>
		</Flex>
	);
};

export default Header;
