export const NAV = [
	{ title: "Home", link: "/" },
	{ title: "Series", link: "/series" },
	{ title: "Movies", link: "/movies" },
	{ title: "Livestreams", link: "/livestreams" },
];

export const CONTINUE_SLIDES = [
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		progress: 30,
		image: require("assets/img/video-placeholder.jpg"),
		link: '/video',
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		progress: 65,
		image: require("assets/img/video-placeholder.jpg"),
		link: '/video'
	}
];

export const SLIDES = [
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
	{
		title: "Head to Toe Flow",
		desc:
			"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
		author: "Nichole Golden",
		image: require("assets/img/video-placeholder.jpg"),
		link: '/detail'
	},
];
