import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { DarkThemeProvider } from "helper/context";

import Detail from "containers/Detail";
import Home from "containers/Home";
import Layout from "hoc/Layout";
import Livestreams from "containers/Livestreams";
import Movies from "containers/Movies";
import PageContainer from "components/PageContainer";
import Series from "containers/Series";
import Video from "containers/Video";

import "./App.css";

function App() {
	return (
		<Router>
			<DarkThemeProvider>
				<Layout>
					<Switch>
						<Route exact path="/">
							<PageContainer header footer>
								<Home />
							</PageContainer>
						</Route>
						<Route exact path="/detail">
							<PageContainer header footer>
								<Detail />
							</PageContainer>
						</Route>
						<Route exact path="/livestreams">
							<PageContainer header footer>
								<Livestreams />
							</PageContainer>
						</Route>
						<Route exact path="/movies">
							<PageContainer header footer>
								<Movies />
							</PageContainer>
						</Route>
						<Route exact path="/series">
							<PageContainer header footer>
								<Series />
							</PageContainer>
						</Route>
						<Route exact path="/video">
							<Video />
						</Route>
					</Switch>
				</Layout>
			</DarkThemeProvider>
		</Router>
	);
}

export default App;
