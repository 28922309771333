import React, { useEffect } from "react";
import { Box } from "rebass";
import { useLocation } from "react-router-dom";

const Livestreams = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Box variant="content" sx={{ height: 300, textAlign: "center" }}>
			Livestreams Page
		</Box>
	);
};

export default Livestreams;
