import React, { useContext } from "react";
import { ThemeProvider } from "theme-ui";

import { theme, theme_dark } from "helper/theme";
import DarkThemeContext from "helper/context";

const Layout = ({ children }) => {
	const { darkTheme } = useContext(DarkThemeContext);

	return (
		<ThemeProvider theme={darkTheme ? theme_dark : theme}>
			{children}
		</ThemeProvider>
	);
};

export default Layout;
