import React, { useState } from "react";

const DarkThemeContext = React.createContext();

export const DarkThemeProvider = ({ children }) => {
	const [darkTheme, setDarkTheme] = useState(true);

	return (
		<DarkThemeContext.Provider
			value={{
				darkTheme,
				setDarkTheme,
			}}
		>
			{children}
		</DarkThemeContext.Provider>
	);
};

export default DarkThemeContext;
