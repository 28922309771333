import React, { useContext } from "react";
import { Image, Box } from "rebass";
import Link from "components/Link";
import DarkThemeContext from "helper/context";

const Logo = (props) => {
	const { darkTheme } = useContext(DarkThemeContext);
	const logo = darkTheme ? "logo_light" : "logo";

	return (
		<Box {...props} mr={20}>
			<Link to="/" sx={{ display: "flex", verticalAlign: "center" }}>
				<Image src={require(`assets/img/${logo}.png`)} alt="Logo" height={25} />
			</Link>
		</Box>
	);
};

export default Logo;
