import React from "react";
import cx from "classnames";
import { useRouteMatch } from "react-router-dom";
import { Box } from "rebass";
import { NAV } from "helper/const";
import Link from "components/Link";
import Search from "../Search";

const Nav = (props) => {
	const match = useRouteMatch();

	return (
		<Box {...props}>
			<Box
				sx={{
					padding: 3,
					display: ["block", "block", "none"],
					bg: "#ddd",
				}}
			>
				<Search mr={2} />
			</Box>
			{NAV.map((nav, index) => (
				<Link
					key={`nav-link-${index}`}
					to={nav.link}
					className={cx({ active: match.url === nav.link })}
					mr={2}
				>
					{nav.title}
				</Link>
			))}
		</Box>
	);
};

export default Nav;
