import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Flex, Text, Heading, Button } from "rebass";

import Banner from "components/Banner";
import VideoSlider from "components/VideoSlider";
import { SLIDES, CONTINUE_SLIDES } from "helper/const";

const Home = () => {
	const [activeSlider, setActiveSlider] = useState(null);
	const [bannerVideo, setBannerVideo] = useState(null);

	const { pathname } = useLocation();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		// Get data
		const video = {
			title: "Head to Toe Flow",
			desc:
				"Awaken and tune up all of the major muscle groups in this steady flow. Build your practice from the ground up beginning with feet, ankles, and legs, then find core e...",
			published_at: "November 2015",
			image: require("assets/img/video-placeholder.jpg"),
			link: "/detail",
			playLink: "/video",
		};
		setBannerVideo(video);
	}, []);

	return (
		<>
			{bannerVideo && (
				<Banner
					backgroundImage={require("assets/img/bg-placeholder-1920.png")}
					sx={{ cursor: "pointer" }}
					onClick={() => history.push(bannerVideo.link)}
				>
					<Flex height="100%" flexWrap="wrap" textAlign={["center", "left"]}>
						<Flex width={[1, 1, 2 / 3]} flexWrap="wrap" alignItems="center">
							<Box>
								<Text variant="badge" mb={2} color="white">
									Top video for you
								</Text>
								<Heading
									variant="h1"
									mb={3}
									color="white"
									sx={{ textShadow: "0 0 5px black" }}
								>
									{bannerVideo.title}
								</Heading>
								<Button
									variant="circle"
									mx="auto"
									mb={3}
									display={["block", "none"]}
									onClick={(e) => {
										e.stopPropagation();
										history.push(bannerVideo.playLink);
									}}
								>
									<i className="icon-play-solid"></i>
								</Button>
								<Text color="white">{bannerVideo.desc}</Text>
							</Box>
						</Flex>
						<Flex
							width={[1, 1, 1 / 3]}
							alignItems={["center", "flex-end"]}
							justifyContent="flex-end"
							flexDirection="column"
							marginTop={2}
						>
							<Button
								variant="circle"
								mb={3}
								display={["none", "block"]}
								onClick={(e) => {
									e.stopPropagation();
									history.push(bannerVideo.playLink);
								}}
							>
								<i className="icon-play-solid"></i>
							</Button>
							<Box variant="link" color="white">
								<Text as="span" mr={2}>
									<b>Add to Playlist</b>
								</Text>
								<Button variant="circleOutline">
									<i className="icon-plus"></i>
								</Button>
							</Box>
						</Flex>
					</Flex>
				</Banner>
			)}
			<Box pb={6}>
				<VideoSlider
					name="continue_watching"
					variant="inner"
					slides={CONTINUE_SLIDES}
					title="Continue watching"
					click={setActiveSlider}
					activeSlider={activeSlider}
					sx={{ borderBottom: "1px dashed rgba(255,255,255,.1)" }}
				/>
				<VideoSlider
					name="recommended"
					variant="inner"
					slides={SLIDES}
					title="Recommended for Asha"
					click={setActiveSlider}
					activeSlider={activeSlider}
				/>
				<VideoSlider
					name="category1"
					variant="inner"
					slides={SLIDES}
					title="Category 1"
					click={setActiveSlider}
					activeSlider={activeSlider}
				/>
				<VideoSlider
					name="category2"
					variant="inner"
					slides={SLIDES}
					title="Category 2"
					click={setActiveSlider}
					activeSlider={activeSlider}
				/>
				<VideoSlider
					name="category3"
					variant="inner"
					slides={SLIDES}
					title="Category 3"
					click={setActiveSlider}
					activeSlider={activeSlider}
				/>
			</Box>
		</>
	);
};

export default Home;
