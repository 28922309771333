import React from "react";
import { Box } from "rebass";

const Banner = (props) => (
	<Box
		{...props}
		sx={{
			display: "block",
			minHeight: 350,
			color: "background",
			backgroundImage: `url(${props.backgroundImage})`,
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			position: "relative",
			...props.sx,
		}}
	>
		<Box
			variant="inner"
			sx={{
				py: 4,
				position: ["relative", "absolute"],
				height: "100%",
				width: "100%",
				left: 0,
				top: 0,
			}}
		>
			{props.children}
		</Box>
	</Box>
);

export default Banner;
